.container {
    width: 100%;
    height: 100%;
    background-color: #131B1F;
    margin-top: 76px;
}

.container header {
    width: 100%;
    height: 820px;
    display: flex;
    flex-direction: column;
    background-image: url(../images/headerimg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.container nav {
    height: 76px;
    width: 1150px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: #131B1F;
}

.container .navfix {
    width: 100%;
    background-color: #131B1F;
}

.navCon {
    height: 76px;
    width: 1150px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container nav img {
    width: 65px;
    height: 35px;
    cursor: pointer;
    justify-self: center;
    align-self: center;
}

.container nav ul {
    display: flex;
    align-items: center;
    gap: 30px;
}

.container nav ul .tilChange {
    display: flex;
    align-items: center;
    gap: 2px;
    color: #E7EFF5;
    cursor: pointer;
    user-select: none;
}

.container nav ul .tilChange img {
    width: 35px;
    height: 35px;
}

.container nav .activeBurger {
    display: flex;
    align-items: center;
    gap: 30px;
}

.container nav .navMenuBtn {
    display: none;
    color: #FFFFFF;
}

.container nav ul li {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 17px;
    cursor: pointer;
}

.container nav ul button {
    width: 118px;
    height: 33px;
    background: #FF346D;
    border-radius: 10px;
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    transition: 0.3s;
}

.container nav ul button:hover {
    transform: translateY(-3px);
    transition: 0.3s;
}

.container header .header {
    width: 1150px;
    margin: 0px auto;
    margin-top: 127px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.container header .header .headerTItle {
    width: 673px;
    font-weight: 800;
    font-size: 72px;
    line-height: 84px;
    display: flex;
    align-items: center;
    letter-spacing: 2.16px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.container header .header .headerInfo {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #E7EFF5;
    margin-top: 20px;
}

.container header .header .headerBtn {
    width: 233.23px;
    height: 51px;
    background: #42BDEB;
    border: 2px solid rgba(66, 189, 235, 0.4);
    box-shadow: 0px 0px 30px #42BDEB;
    border-radius: 99px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    margin-top: 52px;
    transition: 0.3s;
    cursor: pointer;
}

.container header .header .headerBtn:hover {
    transform: translateY(-3px);
    transition: 0.3s;
}

.container main {
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 0px -19px 25px 6px rgba(19, 27, 31, 0.45);
    -moz-box-shadow: 0px -19px 25px 6px rgba(19, 27, 31, 0.45);
    box-shadow: 0px -19px 25px 6px rgba(19, 27, 31, 0.45);
}

.container main .about {
    width: 1150px;
    margin: 0px auto;
    display: flex;
    gap: 68px;
}

.container main .about .aboutInfo {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.container main .about .aboutInfo .aboutInfo_title {
    font-weight: 700;
    font-size: 33px;
    line-height: 42px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.container main .about .aboutInfo p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #A7B4C8;
}

.container main .about .aboutInfo p b {
    color: #FFFFFF;
}

.container main .about .aboutInfo p a {
    color: #4CCAF9;
    cursor: pointer;
}

.container main .gallery {
    width: 1150px;
    margin: 0px auto;
    margin-top: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.container main .gallery .openImg {
    width: 100%;
    height: 100vh;
    background: black;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    top: 0;
    z-index: 999;
}

.container main .gallery .openImg .imgClose {
    width: 50px;
    height: 50px;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
}

.container main .gallery .openImg .imgPrev {
    position: absolute;
    left: 100px;
    width: 50px;
    height: 50px;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
}

.container main .gallery .openImg .imgNext {
    position: absolute;
    right: 100px;
    width: 50px;
    height: 50px;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
}

.container main .gallery .galleryTitle {
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.container main .gallery .gallerys {
    width: 1150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container main .gallery .gallerys .gallerysBox1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container main .gallery .gallerys .gallerysBox1 img {
    cursor: pointer;
}

.gallaryImg {
    border: 5px solid #00c2c238;
    border-radius: 5px;
}

.container main .portfolio {
    width: 1150px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 151px;
    gap: 99px;
}

.container main .portfolio .portfolioTitle {
    font-weight: 700;
    font-size: 33px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
}

.container main .portfolio .portfolios {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1150px;
    gap: 20px;
}

.container main .portfolio .portfolios .portfolioMain {
    width: 515px;
    min-height: 400px;
    background: linear-gradient(90.09deg, #1e2e34 0.06%, #2a2323 62.86%, #443341 100%);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 6px;
    box-shadow: 0px 0px 10px 0.2px #e7eff527;
    transition: transform 0.2s;
}

.container main .portfolio .portfolios .portfolioMain:hover {
    transform: translateY(-3px);
}

.container main .portfolio .portfolios .portfolioMain .portfolioLink {
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-weight: 700;
}

.container main .portfolio .portfolios .portfolioMain span {
    color: rgba(255, 255, 255, 0.845);
    font-weight: 600;
    font-size: 24px;
    margin-left: 20px;
    padding-bottom: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.container main .portfolio .portfolios .portfolioMain .portffolioLogo {
    max-width: 150px;
    margin-left: 20px;
    margin-top: 10px;
}

.container main .portfolio .portfolios .portfolioMain .portfolioImg {
    width: 100%;
    height: auto;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.container main .services {
    width: 1150px;
    margin: 0px auto;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.container main .services .servicesTitle {
    font-weight: 700;
    font-size: 33px;
    line-height: 42px;
    color: #FFFFFF;
}

.container main .services .servicesMain {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.container main .services .servicesMain .servis {
    width: 1150px;
    height: 300px;
    display: flex;
    background: linear-gradient(90.09deg, #15282F 0.06%, #3C3345 62.86%, #63405F 100%);
    border-radius: 20px;
    position: relative;
    transition: 0.3s;
}

.container main .services .servicesMain .servis:hover {
    transform: translateY(-3px);
    transition: 0.3s;
    cursor: pointer;
}

.container main .services .servicesMain .servis .servisInfo {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 55px;
    padding-top: 55px;
    gap: 11px;
}

.container main .services .servicesMain .servis .servisInfo .servisInfo_Title {
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    color: #FFFFFF;
}

.container main .services .servicesMain .servis .servisInfo p {
    width: 393px;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #A7B4C8;
}

.container main .services .servicesMain .servis .servisImg {
    position: absolute;
    right: 90px;
    bottom: 0;
}

.container main .services .servicesMain .servis .servisInfo .servisInfoBtns {
    display: flex;
    gap: 10px;
}

.container main .services .servicesMain .servis .servisInfo .servisInfoBtns button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #131B1F;
    border: 1px solid rgba(66, 189, 235, 0.3);
    border-radius: 10px;
    margin-top: 12px;
    cursor: pointer;
}

.container main .services .servicesMain .servis .servisInfo .servisInfoBtns button img {
    width: 20px;
    height: 20px;
}

.container main .software {
    width: 1150px;
    margin: 0px auto;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 101px;
    position: relative;
}

.container main .software .softwareTitle {
    font-weight: 700;
    font-size: 33px;
    line-height: 42px;
    color: #FFFFFF;
}

.container main .software .softwareMain {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 200px;
}

.container main .software .softwareMain .line {
    position: absolute;
    left: -180px;
}

.container main .software .softwareMain .softwareMainStructura {
    width: 900px;
    height: 155.53px;
    background: #243137;
    border-radius: 20px;
    display: flex;
    position: relative;
}

.container main .software .softwareMain .softwareMainStructura .softwareMainStructuraInfo {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    margin-left: 40px;
}

.container main .software .softwareMain .softwareMainStructura .softwareMainStructuraInfo span {
    font-weight: 500;
    font-size: 26px;
    color: #FFFFFF;
}

.container main .software .softwareMain .softwareMainStructura .softwareMainStructuraInfo p {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #A7B4C8;
}

.container main .software .softwareMain .softwareMainStructura img {
    position: absolute;
    right: 61px;
    top: 14px;
}

.right {
    transform: translateX(-106px);
}

.left {
    transform: translateX(104px);
}

.container main .contact {
    width: 1150px;
    margin: 0px auto;
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
}

.container main .contact .contactTitle {
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    color: #FFFFFF;
}

.container main .contact .contactMain {
    width: 1150px;
    height: 533px;
    display: flex;
    background: #243137;
    border-radius: 20px;
    padding: 50px 160px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-image: url(https://axelhub.com/assets/images/background/form-background-bottom.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.container main .contact .contactMain .contactMainInfos {}

.container main .contact .contactMain .contactMainInfos input {
    width: 400px;
    height: 52px;
    background: #1D282D;
    border-radius: 20px;
    box-shadow: none;
    outline: none;
    border: none;
    padding-left: 31px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #A7B4C8;
    margin: 7px;
}

.container main .contact .contactMain .contactMainInfos input::placeholder {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #A7B4C8;
}

.container main .contact .contactMain .contactMainInfos select {
    width: 400px;
    height: 52px;
    background: #1D282D;
    border-radius: 20px;
    box-shadow: none;
    outline: none;
    border: none;
    padding-left: 31px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #A7B4C8;
    margin: 7px;
}

.container main .contact .contactMain textarea {
    max-width: 820px;
    max-height: 100px;
    min-width: 820px;
    min-height: 100px;
    background: #1D282D;
    border-radius: 20px;
    border: none;
    outline: none;
    padding-top: 15px;
    padding-left: 30px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #A7B4C8;
    margin-top: 10px;
}

.container main .contact .contactMain textarea::placeholder {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #A7B4C8;
}

.container main .contact .contactMain button {
    width: 233.23px;
    height: 51px;
    background: #42BDEB;
    border: 2px solid rgba(66, 189, 235, 0.4);
    box-shadow: 0px 0px 30px #42BDEB;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 35px;
    transition: 0.3s;
}

.container main .contact .contactMain button:hover {
    transform: translateY(-3px);
    transition: 0.3s;
}

.container footer {
    width: 100%;
    height: 350px;
    margin-top: 57px;
    background-color: #243137;
}

.container footer .footerCon {
    width: 1150px;
    margin: 0px auto;
}

.container footer .footerCon .footerTop {
    display: flex;
    padding-top: 50px;
    gap: 52px;
}

.container footer .footerCon .footerTop .footerContact {
    width: 600px;
    height: 170px;
    background: #131B1F;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 15px;
}

.container footer .footerCon .footerTop .footerContact a {
    display: flex;
    align-items: center;
    gap: 18px;
}

.container footer .footerCon .footerTop .footerContact a button {
    width: 30px;
    height: 30px;
    background: #243137;
    border-radius: 5px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container footer .footerCon .footerTop .footerContact a span {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #4CCAF9;
}

.container footer .footerCon .footerTop .footerInfos {
    display: flex;
    gap: 60px;
}

.container footer .footerCon .footerTop .footerInfos ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.container footer .footerCon .footerTop .footerInfos ul span {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}

.container footer .footerCon .footerTop .footerInfos ul li {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #FFFFFF;
    transition: 0.2s ease;
    padding-bottom: 1px;
}

.container footer .footerCon .footerTop .footerInfos ul li:hover {
    border-bottom: 1px solid #4CCAF9;
    cursor: pointer;
    transition: 0.2s;
}

.container footer .footerCon .footerBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 37px;
    border-top: 1px solid #6E7A8066;
    margin-top: 30px;
}

.container footer .footerCon .footerBottom img {
    width: 65px;
    height: 35px;
}

.container footer .footerCon .footerBottom span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
}

.container .faqs {
    width: 1150px;
    margin: 0px auto;
    margin-top: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 47px;
}

.container .faqs .faqsTitle {
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    color: #FFFFFF;
}

.container .faqs .faqss {
    width: 1150px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.container .faqs .faq {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.container .faqs .faq button {
    width: 100%;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    background: #243137;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.container .faqs .faq button span {
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    color: #FFFFFF;
}

.container .faqs .faq button .plus {
    width: 30px;
    height: 30px;
    background-color: #131B1F;
    border-radius: 50%;
}


.container .faqs .faq p {
    min-width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    border: 2px solid #4ccbf93d;
    background-color: #243137;
    border-radius: 5px;
    padding: 24px 25px;
}

.container .faqs .faq p a {
    color: #4CCAF9;
    cursor: pointer;
}


@media screen and (min-width:768px) and (max-width: 1024px) {
    .container header {
        width: 100%;
        background-image: url(../images/headerBcMIni.png);
        background-size: cover;
        padding: 0px 20px;
    }

    .container nav {
        width: 100%;
    }

    .container main {
        width: 100%;
    }

    .container main .about {
        width: 100%;
        flex-direction: column;
        padding: 0px 40px;
        align-items: center;
    }

    .container main .about img {
        width: 400px;
    }

    .container main .gallery {
        width: 100%;
        margin-top: 101px;
    }

    .container main .gallery .gallerys {
        padding: 0px 40px;
        justify-content: center;
        flex-direction: column;
    }

    .container main .gallery .gallerys .gallerysBox1 {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .container main .gallery .gallerys .gallerysBox1 img {
        max-width: 25%;
    }

    .container main .portfolio {
        width: 100%;
    }

    .container main .portfolio .portfolios {
        width: 100%;
        justify-content: center;
    }

    .container main .portfolio .portfolios img {
        max-width: 427px;
        min-width: 427px;
        max-height: 290px;
    }

    .container main .services {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .container main .services .servicesMain {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .container main .services .servicesMain .servis {
        width: 875px;
    }

    .container main .services .servicesMain .servis .servisInfo {
        width: 40%;
        z-index: 1;
    }

    .container main .services .servicesMain .servis .servisImg {
        right: 90px;
        bottom: 0;
    }

    .container main .software {
        width: 100%;
    }

    .container main .software .softwareMain {
        gap: 80px;
    }

    .container main .software .softwareMain .line {
        display: none;
    }

    .right {
        transform: translateX(0px);
    }

    .left {
        transform: translateX(0px);
    }

    .container main .contact {
        width: 100%;
    }

    .container main .contact .contactMain {
        width: 875px;
        padding: 50px 20px;
    }

    .container footer {
        width: 100%;
    }

    .container footer .footerCon {
        width: 100%;
    }

    .container footer .footerCon .footerTop {
        align-items: center;
        justify-content: center;
    }

    .container footer .footerCon .footerTop .footerContact {
        width: 400px;
    }

    .container footer .footerCon .footerBottom {
        width: 100%;
        padding: 30px 20px;
        align-items: center;
    }
}

@media screen and (max-width:414px) and (min-width:360px) {
    .container {
        width: 100%;
    }

    .container header {
        width: 100%;
        background-image: url(../images/mobileBacgrounf.png);
    }

    .container nav {
        width: 100%;
        padding: 0px 20px;
        align-items: center;
        justify-content: space-between;
        background-color: #131B1F;
        z-index: 99;
    }

    .container nav ul {
        width: 100%;
        height: 100vh;
        bottom: 0;
        background-color: #131B1F;
        flex-direction: column;
        position: absolute;
        padding: 30px;
        justify-content: start;
        align-items: start;
        left: 0;
        display: none;
    }

    .container nav .navMenuBtn {
        display: inline;
        z-index: 1;
    }

    .container nav .activeBurger {
        display: flex;
        width: 100%;
        height: 100vh;
        top: 76px;
        background-color: #131B1F;
        flex-direction: column;
        position: absolute;
        padding: 30px;
        justify-content: start;
        align-items: start;
        left: 0;
    }

    .container nav ul li {
        font-size: 24px;
    }

    .container nav ul button {
        width: 300px;
        height: 53px;
        box-shadow: 0px 0px 30px #ff346d;
        font-size: 24px;
    }

    .container header .header {
        width: 100%;
        margin-top: 24px;
        justify-content: center;
        align-items: center;
    }

    .container header .header .headerTItle {
        width: 336px;
        font-weight: 800;
        font-size: 36px;
        line-height: 42px;
        color: #FFFFFF;
        text-align: center;
    }

    .container main .about {
        width: 100%;
        padding: 0px 20px;
        flex-direction: column;
    }

    .container main .gallery {
        width: 100%;
    }

    .container main .gallery .gallerys {
        flex-direction: column;
    }

    .container main .portfolio {
        padding: 0px 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container main .portfolio .portfolios {
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .container main .portfolio .portfolios a {
        width: 100%;
    }

    .container main .portfolio .portfolios a img {
        max-width: 100%;
        min-width: 100%;
        max-height: auto;
    }

    .container main .services {
        width: 100%;
    }

    .container main .services .servicesMain {
        width: 100%;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
    }

    .container main .services .servicesMain .servis {
        width: 100%;
        z-index: 1;
    }

    .container main .services .servicesMain .servis .servisInfo {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        z-index: 2;
    }

    .container main .services .servicesMain .servis .servisInfo p {
        width: 100%;
    }

    .container main .services .servicesMain .servis .servisInfo .servisInfo_Title {
        width: 100%;
    }

    .container main .services .servicesMain .servis .servisImg {
        width: 157px;
    }

    .container main .software {
        width: 100%;
    }

    .container main .software .softwareTitle {
        text-align: center;
        padding: 0px 20px;
    }

    .container main .software .softwareMain {
        padding: 0px 20px;
        width: 100%;
        gap: 50px;
    }

    .container main .software .softwareMain .softwareMainStructura {
        width: 100%;
        height: auto;
        padding-bottom: 22px;
    }

    .container main .software .softwareMain .softwareMainStructura .softwareMainStructuraInfo {
        width: 100%;
        padding-right: 30px;
    }

    .container main .software .softwareMain .softwareMainStructura img {
        width: 76px;
    }

    .container main .software .softwareMain .line {
        display: none;
    }

    .right {
        transform: translateX(0);
    }

    .left {
        transform: translateX(0);
    }

    .container main .contact {
        width: 100%;
        padding: 0px 20px;
    }

    .container main .contact .contactTitle {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
    }

    .container main .contact .contactMain {
        width: 100%;
        height: auto;
        padding: 40px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container main .contact .contactMain .contactMainInfos {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .container main .contact .contactMain .contactMainInfos input {
        width: 100%;
    }

    .container main .contact .contactMain .contactMainInfos select {
        width: 100%;
    }

    .container main .contact .contactMain textarea {
        max-width: 100%;
        max-height: 100px;
        min-width: 100%;
        min-height: 100px;
    }

    .container main .contact .contactMain button {
        width: 250px;
        height: 53px;
    }

    .container footer {
        width: 100%;
        height: auto;
    }

    .container footer .footerCon {
        width: 100%;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
    }

    .container footer .footerCon .footerTop {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        gap: 30px;
    }

    .container footer .footerCon .footerTop .footerContact {
        width: 100%;
        background: transparent;
        border-bottom: 2px solid rgba(110, 122, 128, 0.4);
        border-radius: 0px;
        padding: 0px 0px;
    }

    .container footer .footerCon .footerBottom {
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 2px solid rgba(110, 122, 128, 0.4);
    }

    .container .faqs {
        width: 100%;
        padding: 0px 20px;
    }

    .container .faqs .faqss {
        width: 100%;
    }

    .container .faqs .faq button {
        padding: 10px 24px;
        gap: 10px;
    }

    .container .faqs .faq button span {
        text-align: start;
    }

    .container .faqs .faq button .plus {
        max-width: 30px;
        max-height: 30px;
        min-height: 30px;
        min-width: 30px;
    }
    .container main .portfolio .portfolios .portfolioMain {
        width: 350px;
        gap: 20px;
    }

    .container main .portfolio .portfolios .portfolioMain span {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .container main .gallery .openImg .imgNext ,
    .container main .gallery .openImg .imgPrev
    {
        bottom: 250px;
    }
}