@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
}

body {
    height: 100vh;
    background-color: #131B1F;
}

@media screen and (min-width:768px) and (max-width: 1024px){
    * {
      
    }

    body {
        width: 100%;
    }
}

@media screen and (max-width: 414px) and (min-width: 360px) {
    * {
       
    }

    body {
        width: 100%;
    }
}

/* none class */


.none {
    display: none;
}

/* 404 found style */


.notfound {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a202c;
}


.notfound span {
    font-size: 18px;
    color: #a0aec0;
}